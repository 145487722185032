import React, { useState, useEffect } from "react";
import { graphql, Link } from "gatsby";

import { FaAngleRight, FaAngleLeft } from 'react-icons/fa';

import { Container, Accordian, ImageGallery, VideoGallery, ContactForm, SEOProperty, Maps, SocialShare } from "../components";
import ReactReadMoreReadLess from "react-read-more-read-less";

//CRE components 
import HeroSearch from "cre-plugin/src/components/search";
import { PropertyDetail, Units, Contacts, RelatedProperty } from "cre-plugin/src/components/propertyComponents";

//Styles
import { StyledPage, Grid11, Grid2, Grid22, Grid63, StyledProperty, StyledSnapScroll2 } from "../components/css";

const { makePropertyPagePath } = require("cre-plugin/src/components/utils/urlGenerator");

export default function PropertyToLet({ data, pageContext }) {

  // const [propertyData, setData] = useState(data.property.nodes);

  // useEffect(() => {
  //   var { property_gmaven_key } = data.property.nodes[0];
  //   var query = `query PropertyData($property_gmaven_key: ID!) {
  //       queryPropertyToLet(input: { property_gmaven_key: $property_gmaven_key }) {
  //         objectID
  //         gmaven_mapped_key
  //         property_gmaven_key
  //         property_name
  //         street_address
  //         suburb
  //         city
  //         province
  //         country
  //         cluster
  //         latitude
  //         longitude
  //         marketing {
  //           property_marketing_description
  //           unit_marketing_heading
  //           unit_marketing_description
  //         }
  //         total_property_gla
  //         total_erf_extent
  //         property_category
  //         property_sub_category
  //         unit_category
  //         secondary_category
  //         property_featured
  //         best_image
  //         status
  //         status_date
  //         web_ref
  //         gross_price
  //         net_price
  //         available_type
  //         available_date
  //         unit_id
  //         complex_space
  //         property_responsibility {
  //           gmaven_contact_key
  //           cell_number
  //           email
  //           name
  //           image
  //           role
  //         }
  //         min_gla
  //         max_gla
  //         sub_divisible
  //         this_unit_can_be_leased_by_itself
  //         combinable
  //         property_video
  //         video
  //         property_virtual_tour
  //         virtual_tour
  //         property_update_date
  //         unit_update_date
  //         property_images {
  //           image_path_url
  //           type
  //         }
  //         unit_images {
  //           image_path_url
  //           type
  //         }
  //         office_features {
  //           has_aircon
  //           no_floors
  //           lift_count
  //           lift_cores
  //           has_internet
  //           internet_provider
  //         }
  //         industrial_features {
  //           power_phase
  //           power_output
  //           power_unit
  //           has_yard
  //           height_to_eaves
  //           lux_level
  //           lux_description
  //           gantry_cranes
  //           floor_load_capacity
  //         }
  //         parking {
  //           property_parking
  //           property_parking_ratio
  //           property_parking_open
  //           property_parking_basement
  //           property_parking_covered
  //           property_parking_shade
  //           property_parking_tandem
  //         }
  //         general_features {
  //           property_naming_rights
  //           security_guards
  //           security_hours
  //           security_responsibility
  //           has_security
  //           security_infrustructure
  //           green_certification
  //           green_certification_rating
  //           has_generators
  //           solar
  //           backup_water_supply
  //           canteen
  //           property_kitchenette
  //           gym
  //           boardroom
  //           showers
  //         }
  //       }
  //   }`;

  //   fetch(process.env.GATSBY_GRAPHQL_D9, {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'Accept': 'application/json',
  //     },
  //     body: JSON.stringify({
  //       query,
  //       variables: { property_gmaven_key }
  //     })
  //   })
  //     .then(r => r.json())
  //     .then(dataFetched => setData(dataFetched.data.queryPropertyToLet)).catch((error) => {
  //       console.log(error);
  //       return (
  //         <p>An error has occured. Please try again later.</p>
  //       )
  //     });;
  // }, [])

  const propertyData = data.property.nodes;
  var showPrevNext = 1;
  var { previous, next } = pageContext;
  var relatedProperties = data.related.nodes;

  //On page SEO
  const SEO = {
    street_address: propertyData[0].street_address,
    suburb: propertyData[0].suburb,
    city: propertyData[0].city,
    latitude: propertyData[0].latitude,
    longitude: propertyData[0].longitude,
    property_name: propertyData[0].property_name,
    marketing_heading: propertyData[0].marketing.property_marketing_heading,
    marketing_description: propertyData[0].marketing.property_marketing_description,
    category: propertyData[0].property_category,
    id: propertyData[0].property_gmaven_key,
    deal: "to let",
    price: propertyData[0].gross_price,
    keywords: `${propertyData[0].property_name}, ${propertyData[0].property_category}, ${propertyData[0].suburb}, for sale`,
    url: makePropertyPagePath(propertyData[0], "ToLet", "property"),
    best_image: propertyData[0].best_image,
    contact_name: propertyData[0].property_responsibility ? propertyData[0].property_responsibility.length > 0 ? propertyData[0].property_responsibility[0].name : null : null,
    contact_email: propertyData[0].property_responsibility ? propertyData[0].property_responsibility.length > 0 ? propertyData[0].property_responsibility[0].email : null : null,
    contact_telephone: propertyData[0].property_responsibility ? propertyData[0].property_responsibility.length > 0 ? propertyData[0].property_responsibility[0].cell_number : null : null
  }

  //Images
  var property_images = [];

  if (propertyData[0].property_images) {
    property_images = propertyData[0].property_images;
  }

  var showExt = property_images.filter(image => image.type === "exterior") || [];
  var showInt = property_images.filter(image => image.type === "interior") || [];
  var showFloor = property_images.filter(image => image.type === "floor_plan") || [];
  var showVid = propertyData[0].property_video || [];

  //Pre-select the first category of media
  const imageBoolean = {
    0: showExt.length > 0,
    1: showInt.length > 0,
    2: showFloor.length > 0,
    3: showVid.length > 0
  }

  const firstBoolean = Object.keys(imageBoolean).find(key => imageBoolean[key] === true);
  const showFirst = parseInt(firstBoolean);

  const [open, setOpen] = useState(showFirst);

  //Responsibility
  var property_responsibility = [];

  if (propertyData[0].property_responsibility) {
    property_responsibility = propertyData[0].property_responsibility;
  }

  return (
    <Container page="content" breadcrumbs={false} breadData={{ type: "property", deal: "ToLet", data: propertyData[0] }}>

      {/* Page meta data (title, description, etc.)*/}
      <SEOProperty
        street_address={SEO.street_address}
        suburb={SEO.suburb}
        city={SEO.city}
        latitude={SEO.latitude}
        longitude={SEO.longitude}
        property_name={SEO.property_name}
        marketing_heading={SEO.marketing_heading}
        marketing_description={SEO.marketing_description}
        category={SEO.category}
        id={SEO.id}
        deal={SEO.deal}
        price={SEO.price}
        keywords={SEO.keywords}
        url={SEO.url}
        best_image={SEO.best_image}
        contact_name={SEO.contact_name}
        contact_email={SEO.contact_email}
        contact_telephone={SEO.contact_telephone}
      />

      {/* <TopBanner /> */}

      <StyledPage>

        <StyledProperty>

          <div className="propertyHeroSearch">

            <HeroSearch type="heroProperty" />

          </div>


          <Grid11>

            <div></div>

            <div className="propertyTitle">
              <h1>{propertyData[0].property_name}</h1>

              {
                propertyData[0].marketing.property_marketing_description && (
                  <ReactReadMoreReadLess
                    charLimit={300}
                    readMoreText={"Read more ▼"}
                    readLessText={"Read less ▲"}
                    readMoreClassName="read-more-less more"
                    readLessClassName="read-more-less less"
                  >
                    {propertyData[0].marketing.property_marketing_description}
                  </ReactReadMoreReadLess>
                )
              }


              {/* <p>{propertyData[0].marketing.property_marketing_description && propertyData[0].marketing.property_marketing_description.length > 1000 ? propertyData[0].marketing.property_marketing_description.substring(0, 1000) + "..." : propertyData[0].marketing.property_marketing_description}</p> */}
            </div>

            <div className="propertyMap">
              <Maps lat={propertyData[0].latitude} lng={propertyData[0].longitude} />
            </div>

            <div className="imageCategory">

            </div>

            <div className="propertyImages">

              <Accordian open={open} setOpen={setOpen} ext={showExt.length > 0} int={showInt.length > 0} fp={showFloor.length > 0} vid={showVid.length > 0} />

              <div>

                {/* Exterior images */}
                <ImageGallery title={propertyData[0].property_name} data={showExt} type="exterior" open={open} />

                {/* Interior images */}
                <ImageGallery title={propertyData[0].property_name} data={showInt} type="interior" open={open} />

                {/* Floor plan images */}
                <ImageGallery title={propertyData[0].property_name} data={showFloor} type="floor_plan" open={open} />

                {/* Video */}
                <VideoGallery data={showVid} show={showVid !== null} open={open} />

              </div>

            </div>

          </Grid11>

          <Grid22>

            <div style={{ float: "left" }}>

              {
                previous && showPrevNext === 1 && (

                  <Link to={makePropertyPagePath(previous, "ToLet", "property")} rel="prev">

                    <h5 className="prevNextIcon"><span><FaAngleLeft /></span>{previous.property_name} </h5>

                    {/* <Grid33>

                          <img src={previous.best_image} alt={previous.property_name} width="100%" />

                          <div>
                            <p className="title">{previous.property_name}</p>
                            <p>{previous.property_category}</p>
                            <p>{previous.suburb}</p>
                          </div>

                        </Grid33> */}
                  </Link>

                )
              }

            </div>

            <div style={{ float: "right", textAlign: "right" }}>

              {
                next && showPrevNext === 1 && (

                  <Link to={makePropertyPagePath(next, "ToLet", "property")} rel="next">

                    <h5 className="prevNextIcon">{next.property_name}<span><FaAngleRight /></span> </h5>

                    {/* <Grid34>

                          <div>

                            <p className="title">{next.property_name}</p>
                            <p>{next.property_category}</p>
                            <p>{next.suburb}</p>

                          </div>

                          <img src={next.best_image} alt={next.property_name} width="100%" />

                        </Grid34> */}

                  </Link>

                )
              }

            </div>

          </Grid22>

          <Grid63>

            <PropertyDetail property={propertyData} deal="ToLet" type="property" />

            <SocialShare
              socialConfig={{
                config: {
                  url: `${process.env.GATSBY_WEBSITE_URL}${makePropertyPagePath(propertyData[0], "ToLet", "property")}`,
                  title: propertyData[0].property_name + " - " + propertyData[0].property_category + " to let in " + propertyData[0].suburb,
                  pdf: "propertyToLet",
                  data: propertyData,
                  type: "property"
                },
              }}
            />

          </Grid63>

          <div>



          </div>




          <Grid2>

            <div>

              <Units units={propertyData} />

            </div>

            <div>

              <Contacts contacts={property_responsibility} />

              <ContactForm contacts={property_responsibility} data={`Please contact me regarding leasing a space in ${propertyData[0].property_name}`} />

            </div>

          </Grid2>




          <StyledSnapScroll2>
            <div className="app">
              <div className="full hide-scroll">

                <RelatedProperty property={relatedProperties} deal="ToLet" />

              </div>
            </div>
          </StyledSnapScroll2>

        </StyledProperty>

      </StyledPage>

    </Container>
  )
}

export const query = graphql`
  query($id: ID!, $property_category: String!, $suburb: String!) {
    property: allD9PropertyToLet(filter: {property_gmaven_key: {eq: $id}}) {
      nodes {
        objectID
        gmaven_mapped_key
        property_gmaven_key
        property_name
        street_address
        suburb
        city
        province
        country
        cluster
        latitude
        longitude
        marketing {
          property_marketing_description
          unit_marketing_heading
          unit_marketing_description
        }
        total_property_gla
        total_erf_extent
        property_category
        property_sub_category
        unit_category
        secondary_category
        property_featured
        best_image
        status
        status_date
        web_ref
        gross_price
        net_price
        available_type
        available_date
        unit_id
        complex_space
        property_responsibility {
          gmaven_contact_key
          cell_number
          email
          name
          image
          role
        }
        min_gla
        max_gla
        sub_divisible
        this_unit_can_be_leased_by_itself
        combinable
        property_video
        video
        property_virtual_tour
        virtual_tour
        property_update_date
        unit_update_date
        property_images {
          image_path_url
          type
        }
        unit_images {
          image_path_url
        }
        office_features {
          has_aircon
          no_floors
          lift_count
          lift_cores
          has_internet
          internet_provider
        }
        industrial_features {
          power_phase
          power_output
          power_unit
          has_yard
          height_to_eaves
          lux_level
          lux_description
          gantry_cranes
          floor_load_capacity
        }
        parking {
          property_parking
          property_parking_ratio
          property_parking_open
          property_parking_basement
          property_parking_covered
          property_parking_shade
          property_parking_tandem
        }
        general_features {
          property_naming_rights
          security_guards
          security_hours
          security_responsibility
          has_security
          security_infrustructure
          green_certification
          green_certification_rating
          has_generators
          solar
          backup_water_supply
          canteen
          property_kitchenette
          gym
          boardroom
          showers
        }
      }
    }
    related: allD9PropertyToLet(filter: {property_gmaven_key: {ne: $id}, property_category: {eq: $property_category}, suburb: {eq: $suburb}}) {
      nodes {
        id
        property_gmaven_key
        property_name
        suburb
        min_gla
        max_gla
        gross_price
        net_price
        property_category
        best_image
        latitude
        longitude
        marketing {
          property_marketing_description
        }
      }
    }
  }
`